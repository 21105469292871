import React, { useState } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
const baseUrl = process.env.BASEURL;

const BankDetailsForm = (props) => {
  const [branchName, setBranchName] = useState();
  const [branchCode, setBranchCode] = useState();
  const [bankCode, setBankCode] = useState();
  const [bankAccountNumber, setBankAccountNumber] = useState();
  const [nameOnBankAccount, setNameOnBA] = useState();
  const [nextOfKinName, setKin] = useState();
  const [relationshipToEmployee, setRelOfKin] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [address, setAddress] = useState();
  const [employeePersonalEmail, setPersonalEmail] = useState();
  const [complete, setComplete] = useState();

  const submitBankDetails = () => {
    axios
      .post(`${baseUrl}/user/updateBankDetails`, {
        bankDetails: {
          branchName,
          branchCode,
          bankCode,
          bankAccountNumber,
          nameOnBankAccount,
          nextOfKinName,
          relationshipToEmployee,
          mobileNumber,
          address,
          employeePersonalEmail,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setComplete(true);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  if (complete) {
    return <Redirect to="/" />;
  }

  return (
    <div className="bank-details-form">
      <h3>Bank Details</h3>
      <div className="section">
        <div className="input-wrapper">
          <p className="label">Branch Name</p>
          <input
            type="text"
            value={branchName}
            onChange={(e) => setBranchName(e.target.value)}
          />
        </div>
        <div>
          <div>
            <p className="label">Bank Code</p>
            <input
              type="text"
              value={bankCode}
              onChange={(e) => setBankCode(e.target.value)}
            />
          </div>
        </div>
        <div>
          <p className="label">Branch Code</p>
          <input
            type="text"
            value={branchCode}
            onChange={(e) => setBranchCode(e.target.value)}
          />
        </div>
        <div>
          <p className="label">Bank Account Number</p>
          <input
            type="text"
            value={bankAccountNumber}
            onChange={(e) => setBankAccountNumber(e.target.value)}
          />
        </div>
        <div>
          <p className="label">Name (as per bank account)</p>
          <input
            type="text"
            value={nameOnBankAccount}
            onChange={(e) => setNameOnBA(e.target.value)}
          />
        </div>
      </div>
      <h3>Next-Of-Kin Contact Details</h3>
      <div className="section">
        <div>
          <p className="label">Name Of Next-of-Kin</p>
          <input
            type="text"
            value={nextOfKinName}
            onChange={(e) => setKin(e.target.value)}
          />
        </div>
        <div>
          <p className="label">Relationship to Employee</p>
          <input
            type="text"
            value={relationshipToEmployee}
            onChange={(e) => setRelOfKin(e.target.value)}
          />
        </div>
        <div>
          <p className="label">Mobile Number</p>
          <input
            type="text"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
        </div>
        <div>
          <p className="label">Address</p>
          <textarea
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
      </div>

      <div className="section">
        <div>
          <h3>Email Address</h3>
          <p className="label">Your personal email address</p>
          <input
            type="email"
            value={employeePersonalEmail}
            onChange={(e) => setPersonalEmail(e.target.value)}
          />
        </div>
      </div>

      <button onClick={submitBankDetails}>Submit Bank Details</button>
    </div>
  );
};

export default BankDetailsForm;
