import React, { Component, useState } from "react";
import { fromUnixTime, format } from "date-fns";
import { Redirect } from "react-router-dom";
import store from "store";
import axios from "axios";
import { getImageUrl, getClientName } from "../helpers";
import { NewItemForm } from "./newClaim";
const baseUrl = process.env.BASEURL;

class UpdateClaim extends Component {
  state = { loading: true };
  componentWillMount() {
    let { data, items, total } = this.props.location.state;
    let userId = store.get("user").id;
    let userName = store.get("user").name;
    this.setState({ data, items, userId, userName });
  }

  componentDidMount() {
    axios
      .all([
        axios.post(`${baseUrl}/viewClients`),
        axios.post(`${baseUrl}/getCategories`),
      ])
      .then(
        axios.spread((clientRes, categoryRes) => {
          this.setState({
            clients: clientRes.data,
            categories: categoryRes.data,
            loading: false,
          });
        })
      );
  }
  toFullDate = (d) => {
    return format(fromUnixTime(d), "dd/MM/yyyy");
  };
  toMonthAndYear = (d) => {
    return format(fromUnixTime(d), "MMMM yyyy");
  };

  deleteItem = (i) => {
    let items = this.state.items;
    let newArr = items.slice(0, i).concat(items.slice(i + 1, items.length));
    this.setState({ items: newArr });
  };

  handleNotesChange = (e) => {
    let newState = { ...this.state.data };
    newState.NOTES = e.target.value;
    this.setState({
      data: newState,
    });
  };

  addItem = async (obj) => {
    let url = await getImageUrl(obj.image);
    obj.itemReceipt = url;
    obj.image = undefined;
    obj.clientId = Number(obj.client);
    for (let x in this.state.clients) {
      if (this.state.clients[x].clientId == obj.clientId) {
        obj.clientName = this.state.clients[x].clientName;
      }
    }
    this.setState({ items: [...this.state.items, obj] });
  };

  saveChanges = () => {
    let obj = this.state;
    obj.data.claimLastModifiedBy = this.state.userId;
    let tmpdate = new Date();
    let time = tmpdate.getTime() / 1000;
    obj.data.claimLastModifiedDate = time;

    axios
      .post(`${baseUrl}/user/updateClaim`, obj)
      .then((res) => {
        if (res.data === "DONE") {
          this.setState({
            submitDone: true,
          });
        } else {
          window.alert("Ran into an issue.");
        }
      })
      .catch((e) => console.log(e));
  };

  render() {
    let { data, items, clients, categories } = this.state;
    if (this.state.submitDone) {
      return <Redirect to="/" />;
    }

    if (this.state.loading == true) {
      return <div>loading..</div>;
    }
    return (
      <div className="update-claim">
        <div className="claim-info">
          <h2>
            Updating your claim for{" "}
            {format(Date.parse(data.claimMonth), "MMMM yyyy")}
          </h2>
          <h4>
            Last edit was made on {this.toFullDate(data.claimLastModifiedDate)}{" "}
            by <span className="i fw3"> {data.claimLastModifiedBy}</span>
          </h4>
        </div>
        <table>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Category</th>
            <th>Receipt</th>
            <th>Client</th>
            <th>Job Description</th>
            <th>Amount</th>
            <th>&nbsp;</th>
          </tr>
          <tbody>
            {items.map((x, i) => (
              <tr key={i}>
                <td>{x.date}</td>
                <td>{x.itemName}</td>
                <td>{x.itemCategory}</td>
                <td>
                  <a href={x.itemReceipt} target="_blank">
                    Link
                  </a>{" "}
                </td>
                <td>{getClientName(x.clientId, clients)}</td>
                <td>{x.jobDescription}</td>
                <td>{x.itemAmount.toLocaleString()}</td>
                <td>
                  <button className="alt" onClick={() => this.deleteItem(i)}>
                    🗑️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="wrapper">
          <NewItemForm
            update={(obj) => this.addItem(obj)}
            clients={clients}
            categories={categories}
          />
          <div className="notes">
            <p className="label">Notes</p>
            <textarea value={data.NOTES} onChange={this.handleNotesChange} />
            <button className="alt mb6" onClick={this.saveChanges}>
              Save Changes 💾
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateClaim;
