import React, { useState } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
const baseUrl = process.env.BASEURL;

const FirstLogin = (props) => {
  const [pw1, updatePW1] = useState();
  const [pw2, updatePW2] = useState();
  const [hasReset, setReset] = useState(false);

  const submit = () => {
    if (pw1 != pw2) {
      window.alert("Your passwords don't seem to match, please try again");
    } else {
      axios
        .post(`${baseUrl}/reset_after_login`, { pw: pw1 })
        .then((res) => {
          if (res.data == "PW_UPDATED") {
            setReset(true);
          }
        })
        .catch((e) => console.log(e));
    }
  };
  if (hasReset == true) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="first-login">
        <h2>
          You seem to be logging in for the first time, please set-up a new
          password.
        </h2>
        <input
          type="password"
          name="pw1"
          value={pw1}
          placeholder="New Password"
          onChange={(e) => updatePW1(e.target.value)}
        />
        <input
          type="password"
          name="pw2"
          value={pw2}
          placeholder="Re-Type Password"
          onChange={(e) => updatePW2(e.target.value)}
        />
        <button onClick={submit}>Submit</button>
      </div>
    );
  }
};

export default FirstLogin;
