import React, { useState, useRef } from "react";
import Papa from "papaparse";
import csvFile from "../static/samplecsv_stacke.csv";
import { Link } from "react-router-dom";
import { checkDateEnding } from "../helpers";
import axios from "axios";
const baseUrl = process.env.BASEURL;

const BulkImport = (props) => {
  const csvRef = useRef(null);
  const [addingUser, toggleAdd] = useState(false);
  const [userBeingAdded, setUserBeingAdded] = useState();
  const [showTable, toggleTable] = useState(false);
  const [userArray, updateArray] = useState();

  const runImport = () => {
    Papa.parse(csvRef.current.files[0], {
      header: true,
      skipEmptyLines: "greedy",
      complete: function (results) {
        showUserTable(results.data);
        handleData(results.data);
      },
    });
  };

  const handleData = (arr) => {
    arr.forEach((item) => {
      toggleAdd(true);
      setUserBeingAdded(`Currently adding ${item.name} to the database.`);
      item.isSubAdmin = JSON.parse(item.isSubAdmin.toLowerCase());
      if (item.image == "") {
        item.image =
          "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png";
      }

      item.joinDate = checkDateEnding(item.joinDate);

      axios.post(`${baseUrl}/register`, { userDetails: item }).then((res) => {
        if (res.data == "USER_EXISTS") {
          window.alert(`${item.name} exists in the database, skipping.`);
        }
      });
    });
    setUserBeingAdded(
      "The whole file has been processed. Your users should've got their invitation links now."
    );
  };

  const showUserTable = (obj) => {
    updateArray(obj);
    toggleTable(true);
  };

  return (
    <div className="bulk-import">
      <h2>Use the file-picker below to upload your CSV file.</h2>
      <p>
        Download the demo CSV file{" "}
        <Link to={csvFile} target="_blank" download>
          here.
        </Link>
      </p>
      <input type="file" ref={csvRef} />
      <button onClick={runImport}>Begin Import</button>
      {addingUser && <MessageBox msg={userBeingAdded} />}
      {showTable && <UserTable users={userArray} />}
    </div>
  );
};

const MessageBox = ({ msg }) => (
  <div>
    <p>{msg} </p>
  </div>
);

const UserTable = ({ users }) => (
  <div className="users-table">
    <h3>Users Parsed from CSV</h3>
    <ul>
      {users.map((x, i) => (
        <li key={i}>
          <b>{x.name}</b>: <em>{x.email}</em>
        </li>
      ))}
    </ul>
  </div>
);

export default BulkImport;
