import React, { Component } from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
const baseUrl = process.env.BASEURL;

class ViewClients extends Component {
  state = {
    loading: true,
    newClientName: "",
  };

  componentDidMount() {
    axios
      .post(`${baseUrl}/viewClients`)
      .then((res) => {
        if (res.data.auth != true) {
          if (res.data.msg == "TOKEN_INVALID") {
            confirm("Your session has expired, please log in again");
            this.setState({
              session_expired: true,
            });
          }
        }
        this.setState({
          items: res.data,
          loading: false,
          session_expired: false,
        });
      })
      .catch((e) => console.log(e));
  }

  handleNewClient = (e) => {
    this.setState({
      newClientName: e.target.value,
    });
  };

  addNewClient = () => {
    let x = window.confirm(
      `Are you sure you want to add ${this.state.newClientName} to the database. This action cannot be undone.`
    );
    if (x == true) {
      axios
        .post(`${baseUrl}/admin/newClient`, {
          clientName: this.state.newClientName,
        })
        .then((res) => {
          if (res.status == 200) {
            let items = this.state.items;
            items.push({
              clientId: res.data.lastInsertRowid,
              clientName: this.state.newClientName,
            });
            this.setState({
              items,
              newClientName: "",
            });
          }
        });
    }
  };

  render() {
    if (this.state.loading == true) {
      return <div>loading...</div>;
    }
    if (this.state.session_expired == true) {
      return (
        <Redirect
          to={{ pathname: "/login", state: { error: "SESSION_EXPIRED" } }}
        />
      );
    }
    return (
      <div className="clients">
        <h2>Current Clients</h2>
        <ul className="pl0 list">
          {this.state.items.map((x, i) => (
            <li key={x.clientId}>{x.clientName}</li>
          ))}
        </ul>
        <div className="input-wrapper">
          <input
            type="text"
            value={this.state.newClientName}
            onChange={this.handleNewClient}
          />
          <button onClick={this.addNewClient}>Add Client</button>
        </div>
      </div>
    );
  }
}

export default ViewClients;
