import React from "react";
import ReactDOM from "react-dom";
import App from "./app.js";
import axios from "axios";
import store from "store";
import "react-toggle/style.css";

let user = store.get("user");

console.log(user);

//axios.defaults.baseURL = "localhost:4000";
if (user != undefined) {
  axios.defaults.headers["x-access-token"] = user.token;
}

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(<App />, document.getElementById("root"));
