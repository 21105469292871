import React, { Component, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
const baseUrl = process.env.BASEURL;

class AdminViewUser extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    let userId = this.props.match.params.id;
    axios
      .post(`${baseUrl}/admin/viewUser/${userId}`)
      .then((res) => {
        this.setState({
          userDetails: res.data,
          loading: false,
        });
      })
      .catch((e) => console.log(e.message));
  }
  render() {
    if (this.state.loading == true) {
      return <p>Loading...</p>;
    } else {
      let d = this.state.userDetails;
      return (
        <div className="admin-user-profile">
          <Link to="/viewUsers">
            <button className="alt">🔙 All Users</button>
          </Link>
          <div className="profile-top">
            <div className="image-container">
              <img src={d.userImageURL} alt={d.userName} />
            </div>
            <div className="section">
              <h1>{d.userName}</h1>
              <h3>
                📧 <a href={`mailto:${d.userEmail}`}>{d.userEmail}</a> //
                <a href={`mailto:${d.employeePersonalEmail}`}>
                  {d.employeePersonalEmail}
                </a>{" "}
              </h3>
              <h3>📱 {d.userPhoneNumber}</h3>
            </div>
            <div className="section second">
              <h3>
                📆 Started on <span>{d.userJoinDate}</span>
              </h3>
              <h3>👥 {d.userRole}</h3>
            </div>
          </div>
          {d.hasBankDetails == true ? (
            <BankDetailsSection data={d} />
          ) : (
            <MissingDetails email={d.userEmail} />
          )}
          <ResetPassword email={d.userEmail} />
        </div>
      );
    }
  }
}

export const BankDetailsSection = ({ data }) => (
  <div className="bank-details">
    <div>
      <h2>🏦 Bank Details</h2>
      <table>
        <tbody>
          <tr>
            <td>Branch Name</td>
            <td>{data.branchName}</td>
          </tr>
          <tr>
            <td>Bank Code</td>
            <td>{data.bankCode}</td>
          </tr>
          <tr>
            <td>Branch Code</td>
            <td>{data.branchCode}</td>
          </tr>
          <tr>
            <td>Bank Account Number</td>
            <td>{data.bankAccountNumber}</td>
          </tr>
          <tr>
            <td>Name (as per bank account)</td>
            <td>{data.nameOnBankAccount}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <h2>Next-Of-Kin Contact Details</h2>
      <table>
        <tbody>
          <tr>
            <td>Employee</td>
            <td>{data.userName}</td>
          </tr>
          <tr>
            <td>Name of Next of Kin</td>
            <td>{data.nextOfKinName}</td>
          </tr>
          <tr>
            <td>Relationship to Employee</td>
            <td>{data.relationshipToEmployee}</td>
          </tr>
          <tr>
            <td>Mobile Number</td>
            <td>{data.mobileNumber}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>{data.address}</td>
          </tr>
        </tbody>
      </table>
    </div>
    {/*<h3>Employee's Personal Email Address</h3>
    <a href={`mailto:${data.employeePersonalEmail}`}>
      {data.employeePersonalEmail}
    </a> */}{" "}
  </div>
);

const MissingDetails = ({ email }) => {
  const [nudged, toggleNudge] = useState(false);
  const sendNudge = () => {
    axios
      .post(`${baseUrl}/admin/nudgeBankDetails`, { emailOfUser: email })
      .then((res) => {
        if (res.data == "EMAIL_SENT") {
          toggleNudge(true);
        }
      });
  };

  if (nudged == false) {
    return (
      <div className="missing-details">
        <p>This user hasn't added in their bank details yet.</p>
        <button onClick={sendNudge}>Give Them a Nudge</button>
      </div>
    );
  } else {
    return (
      <div className="missing-details">
        <p>We've sent them an email asking them to fill in their details.</p>
      </div>
    );
  }
};

const ResetPassword = ({ email }) => {
  const [newPass, setNewPass] = useState();
  const [pwUpdated, togglePWUpdate] = useState(false);

  const submit = () => {
    if (newPass == "" || newPass == undefined) {
      window.alert("Please fill in a new password");
    } else {
      axios
        .post(`${baseUrl}/admin/resetUserLogin`, {
          userEmail: email,
          password: newPass,
        })
        .then((res) => {
          togglePWUpdate(true);
        });
    }
  };

  if (pwUpdated) {
    return (
      <div className="reset-password">
        <p>
          We've reset the password for {email}. They can log in using the
          password <code>{newPass}</code>
        </p>
      </div>
    );
  } else {
    return (
      <div className="reset-password">
        <p>
          Is this user having trouble logging in? You can reset their password
          via the form below.
        </p>
        <div className="form-holder">
          <label for="newPass">New Password</label>
          <input
            type="text"
            value={newPass}
            onChange={(e) => setNewPass(e.target.value)}
          />
        </div>
        <small>
          Give this password to {email}. They will use it to log in, and then
          they will be prompted to reset their password.
        </small>
        <button onClick={submit}>Submit</button>
      </div>
    );
  }
};
export default AdminViewUser;
