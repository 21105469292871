import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
const baseUrl = process.env.BASEURL;

class ViewUsers extends Component {
  state = {
    loading: true,
    searchFilter: "",
    filtered: [],
  };

  componentDidMount() {
    axios
      .post(`${baseUrl}/admin/viewUsers`)
      .then((res) => {
        this.setState({
          users: res.data,
          loading: false,
        });
      })
      .catch((e) => console.log(e.message));
  }

  handleSearch = (e) => {
    let arr = [...this.state.users];
    let clonedArray = [...this.state.users];
    let newArr = clonedArray.filter((v, i, a) => {
      if (v.userName.toLowerCase().includes(e.target.value.toLowerCase())) {
        return v;
      }
    });

    if (e.target.value.length < 2) {
      this.setState({ searchFilter: e.target.value, filtered: [] });
    } else {
      this.setState({ searchFilter: e.target.value, filtered: newArr });
    }
  };

  render() {
    if (this.state.loading == true) {
      return <p>loading..</p>;
    } else {
      let list = this.state.users;

      if (this.state.filtered.length > 0) {
        list = this.state.filtered;
      }
      return (
        <div className="user-list">
          <div className="top-section">
            <h2>Users</h2>
            <Link to="/newEmployee">
              <button>Add New User</button>
            </Link>
          </div>
          <input
            type="text"
            value={this.state.searchFilter}
            onChange={this.handleSearch}
            placeholder="Filter Users.."
            className="search-filter"
          />
          <div className="user-container">
            {list.map((x) => (
              <UserItem key={x.userId} data={x} />
            ))}
          </div>
        </div>
      );
    }
  }
}

export default ViewUsers;

const UserItem = ({ data }) => (
  <div className="user-item">
    <img
      src={data.userImageURL}
      alt={data.userName}
      className={data.userRole == "ADMIN" ? "admin" : "user"}
    />
    <span>
      <h4>{data.userName}</h4>
      <p>{data.userEmail}</p>
    </span>
    <p>{data.userRole}</p>
    <p>{data.userJoinDate}</p>
    <Link to={`/viewUser/${data.userId}`}>
      <button className="alt">View Profile 🔬</button>
    </Link>
  </div>
);
