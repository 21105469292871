import React, { Component } from "react";
import axios from "axios";
import { fromUnixTime, formatDistance, format } from "date-fns";
import { getClientName } from "../helpers";
import { Redirect, Link } from "react-router-dom";
const baseUrl = process.env.BASEURL;

class AdminViewClaim extends Component {
  state = {
    loading: true,
    notes: "",
    isApproved: false,
    redirectHome: false,
    showConversion: false,
  };

  componentDidMount() {
    axios
      .post(`${baseUrl}/admin/viewClaim`, {
        claimId: this.props.match.params.id,
      })
      .then((res) => {
        this.setState({
          data: this.props.location.state,
          items: res.data,
          notes: this.props.location.state.NOTES,
        });
      })
      .then(() => {
        this.calcTotal();
      })
      .then(() => {
        axios.post(`${baseUrl}/viewClients`).then((res) => {
          this.setState({
            clients: res.data,
            loading: false,
          });
        });
      })
      .catch((e) => console.log(e));
  }

  approveItem = (itemId, index) => {
    let claimId = this.state.data.claimId;
    axios
      .post(`${baseUrl}/admin/approveClaimItem`, { claimId, itemId })
      .then((res) => {
        if (res.data.msg == "APPROVED") {
          let arr = this.state.items;
          arr[index].isApproved = 1;
          this.setState({
            items: arr,
          });
        }
      })
      .then(() => this.calcTotal())
      .catch((e) => console.log(e));
  };

  approveAllItems = () => {
    let claimId = this.state.data.claimId;
    let items = this.state.items;
    items.forEach((item, index) => {
      this.approveItem(item.itemId, index);
    });
  };

  calcTotal = () => {
    let arr = this.state.items;
    let filteredArray = arr.filter((item) => item.isApproved == 1);

    let newItem = { itemAmount: 0 };
    if (filteredArray.length > 0) {
      newItem = filteredArray.reduce((a, b) => ({
        itemAmount: a.itemAmount + b.itemAmount,
      }));

      this.setState({
        total: newItem.itemAmount,
      });
    }
  };

  handleNotesChange = (e) => {
    this.setState({
      notes: e.target.value,
    });
  };

  saveChanges = (isApproved) => {
    // this is where we have to store last modified date, last modified user, and status
    // change from submitNotesChange to saveChanges
    // also integrate with the save & approve button
    let notes = this.state.notes;
    let tmpdate = new Date();
    let date = tmpdate.getTime() / 1000;
    let claimId = this.state.data.claimId;
    let claimee = this.state.data.claimee;
    let claimMonth = this.state.data.claimMonth;
    let obj = { notes, date, isApproved, claimId, claimee, claimMonth };

    axios
      .post(`${baseUrl}/admin/updateClaim`, obj)
      .then((res) => {
        if (res.data.changes && res.data.changes > 0) {
          window.alert("data has been saved. You may return home now.");
        }
      })
      .catch((e) => console.log(e));
  };

  approveClaim = () => {
    this.saveChanges(true);
  };

  unlockClaim = () => {
    let claimId = this.state.data.claimId;
    let claimee = this.state.data.claimee;
    axios
      .post(`${baseUrl}/admin/unlockClaim`, { claimId, claimee })
      .then((res) => {
        if (res.data.msg == "UNLOCKED") {
          let check = window.confirm(
            "The claim has been unlocked, the user has been notified that they can now make changes. We will notify you when they submit their claim again. Click OK to return to the dashboard, or cancel to stay on this page."
          );
          if (check == true) {
            this.setState({
              redirectHome: true,
            });
          }
        }
      })
      .catch((e) => console.log(e));
  };

  convertCurrency = async () => {
    let d = this.state.data;
    let items = this.state.items;
    let arrOfItems = [];
    let curr = d.claimCurrency;
    let conv;

    if (curr == "SGD") {
      conv = "IDR";
    } else {
      conv = "SGD";
    }

    try {
      let responseData = await axios.get(`${baseUrl}/exchangeRate/${curr}`);
      let rate = responseData.data.rates[conv];
      let rateDate = responseData.data.date;

      items.forEach((item) => {
        let convertedAmount = item.itemAmount * rate;
        arrOfItems.push({
          name: item.itemName,
          amount: item.itemAmount,
          amountConverted: convertedAmount,
        });
      });

      this.setState({
        showConversion: true,
        rate,
        rateDate,
        conversionTo: conv,
        convertedItems: arrOfItems,
      });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    let { data, items, clients } = this.state;
    if (this.state.loading === true) {
      return <p>Loading...</p>;
    }

    if (this.state.redirectHome == true) {
      return <Redirect to="/" />;
    }

    let notifyAmount = 500;
    if (data.claimCurrency == "IDR") {
      notifyAmount = 5000000;
    }
    return (
      <div className="admin-view-claim">
        <div className="top-section">
          <h2 className="mb2">Claim submitted by {data.claimeeName}</h2>
          <h3 className="fw4 mb2">
            For the month of {format(Date.parse(data.claimMonth), "MMMM yyyy")}
          </h3>
          <h4 className="fw3 mb3 silver">
            Last modified by {data.claimLastModifiedBy},{" "}
            {formatDistance(
              fromUnixTime(data.claimLastModifiedDate),
              new Date()
            )}{" "}
            ago{" "}
          </h4>
          <h5 className="fw3 silver mb2">
            Amounts shown are in {data.claimCurrency}
          </h5>
        </div>
        <div className="admin-claim-items-table">
          <table>
            <thead>
              <tr>
                <th className="fw5">Name</th>
                <th className="fw5">Category</th>
                <th className="fw5">Client</th>
                <th className="fw5">Job Description</th>
                <th className="fw5">Amount</th>
                <th className="fw5">Receipt</th>
                <th className="fw5">Is Approved?</th>
              </tr>
            </thead>
            <tbody>
              {items.map((x, i) => (
                <tr key={i}>
                  <td>{x.itemName}</td>
                  <td>{x.itemCategory}</td>
                  <td>{getClientName(x.clientId, clients)}</td>
                  <td>{x.jobDescription == null ? "N/A" : x.jobDescription}</td>
                  <td
                    className={x.itemAmount >= notifyAmount ? "warn" : "fine"}
                  >
                    {x.itemAmount}
                  </td>
                  <td>
                    <a
                      href={x.itemReceipt}
                      className="link dim"
                      target="_blank"
                    >
                      Link
                    </a>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      className="tc"
                      checked={x.isApproved ? true : false}
                      onChange={(l) => this.approveItem(x.itemId, i)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="btns">
            <button className="alt mr3" onClick={this.approveAllItems}>
              Approve All Items
            </button>
            {this.state.showConversion == false && (
              <button className="alt mv3" onClick={this.convertCurrency}>
                Show {data.claimCurrency == "SGD" ? "IDR" : "SGD"} Conversion
              </button>
            )}
          </div>
        </div>
        {this.state.showConversion == true && (
          <ConversionTable
            r={this.state.rate}
            d={this.state.rateDate}
            items={this.state.convertedItems}
          />
        )}
        <div className="notes">
          <p className="mb3 mt4">Notes</p>
          <textarea
            value={this.state.notes}
            onChange={this.handleNotesChange}
            cols="50"
            rows="8"
          />
        </div>
        {this.state.total && (
          <p className="mt3 mb5 f3">
            Total Amount of approved items: {this.state.data.claimCurrency}{" "}
            {this.state.total.toLocaleString()}
            {this.state.showConversion && (
              <span>
                | {this.state.conversionTo}{" "}
                {(this.state.total * this.state.rate).toLocaleString()}
              </span>
            )}
          </p>
        )}
        <div className="btns mv4 flex w-70">
          <button className="alt" onClick={() => this.saveChanges(false)}>
            Save Changes
          </button>
          <button className="alt" onClick={this.unlockClaim}>
            Unlock Claim 🔓
          </button>
          <Link
            to={{
              pathname: `/claimreport`,
              state: [data, items, clients, this.state.total],
            }}
          >
            <button className="alt">View PDF</button>
          </Link>
          <button onClick={this.approveClaim} className="big-btn">
            Approve Claim
          </button>
        </div>
      </div>
    );
  }
}

const ConversionTable = ({ r, d, items }) => (
  <div className="conversion-table">
    <h3>Conversion Table</h3>
    <p>
      Based on a rate of {r}, attained from the ECB at {d}
    </p>
    <div className="conversion-inner">
      <div className="conversion-row head">
        <span>Item Name</span>
        <span>Original Amount</span>
        <span>Converted Amount</span>
      </div>
      {items.map((x, i) => (
        <div key={i} className="conversion-row">
          <span>{x.name}</span>
          <span>{x.amount}</span>
          <span>{x.amountConverted}</span>
        </div>
      ))}
    </div>
  </div>
);

export default AdminViewClaim;
