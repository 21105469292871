import React, { Component, useState, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { getImageUrl } from "../helpers";
import { format, fromUnixTime } from "date-fns";
import store from "store";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Danger } from "../components/alert";
import AdminInputSuggest from "../components/adminInputSuggest";
import DatePicker from "react-datepicker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import ReceiptIcon from "../static/icons/receipt.svg";
import "react-datepicker/dist/react-datepicker.css";
import { useCycle, motion, AnimatePresence } from "framer-motion";
import { Code } from "react-content-loader";
const baseUrl = process.env.BASEURL;

class NewClaim extends Component {
  state = {
    managerEmail: "",
    currency: "IDR",
    notes: "",
    claimItems: [],
    month: "2019-04",
    loading: true,
    hasError: false,
    errorMsg: "",
    itemLoading: false,
    showClaims: true,
  };

  componentWillMount() {
    let d = new Date();
    if (d.getDate() > 20) {
      d = new Date(d.setMonth(d.getMonth() + 1));
    }
    let formattedDate = format(d, "yyyy-MM");
    const user = store.get("user");
    this.setState({
      user: user.email,
      month: formattedDate,
    });
  }

  componentDidMount() {
    axios
      .all([
        axios.post(`${baseUrl}/viewClients`),
        axios.post(`${baseUrl}/getCategories`),
        axios.post(`${baseUrl}/user/getAdminList`),
      ])
      .then(
        axios.spread((clientRes, categoryRes, adminRes) => {
          this.setState({
            clients: clientRes.data,
            categories: categoryRes.data,
            admins: adminRes.data,
          });
        })
      )
      .then(() => {
        axios
          .post(`${baseUrl}/user/getClaimDates`)
          .then((res) => {
            this.setState({
              arrayOfClaimDates: res.data,
              loading: false,
            });
          })
          .then(() => {
            let e = this.checkDateMatch(this.state.month);
            if (e == true) {
              this.setState({
                hasError: true,
                errorMsg: "You already have a claim for the selected month.",
              });
            }
          });
      });
  }

  handleCurrencyChange = (e) => {
    this.setState({
      currency: e.target.value,
    });
  };

  handleToggleClaims = () => {
    this.setState({
      showClaims: !this.state.showClaims,
    });
  };

  addNewClaim = async (obj) => {
    this.setState({ itemLoading: true });
    if (typeof obj.date === "object") {
      obj.date = format(obj.date, "dd-MM-yyyy");
    }
    let url = await getImageUrl(obj.image);
    //let url = "https://placeholder.it/200x200";
    obj.itemReceipt = url;
    obj.image = undefined;
    obj.clientId = Number(obj.client);
    for (let x in this.state.clients) {
      if (this.state.clients[x].clientId == obj.clientId) {
        obj.clientName = this.state.clients[x].clientName;
      }
    }
    this.setState({
      claimItems: [...this.state.claimItems, obj],
      itemLoading: false,
    });
  };

  deleteItem = (i) => {
    let items = this.state.claimItems;

    let x = window.confirm(
      `Are you sure you want to delete ${items[i].itemName}`
    );
    if (x == true) {
      let newArr = items.slice(0, i).concat(items.slice(i + 1, items.length));
      this.setState({ claimItems: newArr });
    }
  };

  handleMonthChange = (e) => {
    let date = format(e, "yyyy-MM");
    let datesMatch = this.checkDateMatch(date);
    if (datesMatch == true) {
      this.setState({
        month: date,
        hasError: true,
        errorMsg: "You already have a claim for the selected month.",
      });
    } else {
      this.setState({ month: date, hasError: false });
    }
  };

  handleManagerChange = (x) => {
    this.setState({
      managerEmail: x,
    });
  };

  checkDateMatch = (currentDate) => {
    let error = false;
    let dates = this.state.arrayOfClaimDates;
    dates.forEach((item) => {
      let formatted = format(fromUnixTime(item), "yyyy-MM");
      if (currentDate === formatted) {
        error = true;
      } else {
        error = false;
      }
    });
    return error;
  };

  createClaim = () => {
    let tmpdate = new Date();
    let time = tmpdate.getTime() / 1000;
    let claimData = {
      claimee: this.state.user,
      admin: this.state.managerEmail,
      claimDate: time,
      claimLastModifiedBy: this.state.user,
      claimStatus: "OPEN",
      claimCurrency: this.state.currency,
      claimItems: this.state.claimItems,
      notes: this.state.notes,
      claimMonth: this.state.month,
    };

    axios
      .post(`${baseUrl}/user/newClaim`, claimData)
      .then((res) => {
        if (res.data == "ADMIN_NOT_EXIST") {
          window.alert(
            "Sorry, it looks like the admin you've tagged doesn't exist. Try another email maybe?"
          );
        }
        if (res.data == "DONE") {
          this.setState({
            hasSubmit: true,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    if (this.state.hasSubmit == true) {
      return <Redirect to="/" />;
    }

    if (this.state.loading == true) {
      return <div>Loading...</div>;
    }
    return (
      <div className="new-claim-container">
        <div className="new-claim">
          {this.state.hasError && <Danger message={this.state.errorMsg} />}
          <h1>Start a New Claim</h1>
          <div className="form">
            <div className="form-top">
              <div className="form-item datepicker">
                <p className="label">Claim for the month of</p>
                <DatePicker
                  selected={this.state.month}
                  dateFormat="yyyy-MM"
                  className="silver"
                  showMonthYearPicker
                  onChange={(e) => this.handleMonthChange(e)}
                />
              </div>
              <div className="form-item">
                <p className="label">Manager' Email</p>
                <AdminInputSuggest
                  admins={this.state.admins}
                  value={this.state.managerEmail}
                  oc={(e) => this.handleManagerChange(e)}
                />
              </div>
              <div className="form-item">
                <p className="label">Select your currency</p>
                <select
                  value={this.state.currency}
                  onChange={this.handleCurrencyChange}
                >
                  <option value="SGD">SGD</option>
                  <option value="IDR">IDR</option>
                </select>
              </div>
            </div>
            <NewItemForm
              update={(i) => this.addNewClaim(i)}
              clients={this.state.clients}
              categories={this.state.categories}
            />
            <div className="form-item notes">
              <p className="label">Additional Notes</p>
              <textarea
                value={this.state.notes}
                onChange={(e) => this.setState({ notes: e.target.value })}
              />
            </div>
          </div>
        </div>
        <AnimatePresence>
          {this.state.showClaims && (
            <ClaimTable
              items={this.state.claimItems}
              clients={this.state.clients}
              deleteItem={(i) => this.deleteItem(i)}
              submit={() => this.createClaim()}
              currency={this.state.currency}
              itemLoading={this.state.itemLoading}
            />
          )}
        </AnimatePresence>
        <div className="btn toggle-claims" onClick={this.handleToggleClaims}>
          {this.state.showClaims ? "Hide Claim Items ˄" : "Show Claim Items ˅"}{" "}
        </div>
      </div>
    );
  }
}

const ClaimTable = ({
  items,
  clients,
  deleteItem,
  submit,
  currency,
  itemLoading,
}) => {
  if (items.length > 0) {
    return (
      <motion.div
        className="claim-table pa4"
        key="claims-table"
        intial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <h2>Your Claim Items</h2>
        {items.map((x, i) => (
          <div className="item">
            <div className="row1">
              <p className="identifier">{x.date.toString()}</p>
            </div>
            <div className="row2">
              <span className="col1">
                <h3>{x.itemName}</h3>
                <p>{x.itemCategory}</p>
              </span>
              <span className="col2">
                <h3>{x.clientName}</h3>
                <p>{x.jobDescription}</p>
              </span>
              <span className="col3">
                <a href={x.itemReceipt} target="_blank">
                  Receipt
                </a>
              </span>
              <span className="col4">
                <h3>
                  {currency == "IDR"
                    ? `Rp. ${x.itemAmount.toLocaleString()}`
                    : `$ ${x.itemAmount}`}
                </h3>
              </span>
              <span className="col5">
                <p onClick={() => deleteItem(i)}>🗑</p>
              </span>
            </div>
          </div>
        ))}
        {itemLoading && (
          <Code foregroundColor={"#333"} backgroundColor={"#999"} />
        )}
        <div className="submit-btns">
          <button onClick={submit} className="blk">
            Save Claim
          </button>
          <small>
            Note: This doesn't submit your claim to the administrator, it just
            saves it to your dashboard. You can come back and update this claim
            before submitting it to your admin.
          </small>
        </div>
      </motion.div>
    );
  } else {
    if (itemLoading == true) {
      return (
        <div className="claim-table pa4">
          <Code />
        </div>
      );
    } else {
      return (
        <motion.div
          className="claim-table pa4"
          initial={{ opacity: 0, y: -200 }}
          animate={{ opacity: 1, y: -0 }}
          exit={{ opacity: 0, y: -200 }}
        >
          <h3 className="empty-state">
            When you add items, they'll show up here.
          </h3>
        </motion.div>
      );
    }
  }
};

export const NewItemForm = (props) => {
  const onDrop = useCallback((af) => {
    setFile(af[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
  });

  const [itemName, setName] = useState();
  const [file, setFile] = useState();
  const [itemCategory, setCategory] = useState(
    props.categories[0].categoryName
  );
  const [itemAmount, setAmount] = useState();
  const [date, setDate] = useState();
  const [jobDescription, setJob] = useState();
  const [client, setClient] = useState(props.clients[0].clientId);
  const submitForm = () => {
    if (file == undefined) {
      window.alert(
        "All fields other than Job Number are required. Please make sure you've filled in all the info."
      );
    } else if (itemAmount != "" || itemName != "") {
      let image = file;
      props.update({
        itemName,
        itemAmount,
        itemCategory,
        image,
        client,
        jobDescription,
        date,
      });
      setName("");
      setCategory(props.categories[0].categoryName);
      setAmount(0);
      setClient(props.clients[0].clientId);
      setJob("");
      setFile();
    } else {
      window.alert(
        "All fields other than Job Number are required. Please make sure you've filled in all the info."
      );
    }
  };

  return (
    <div className="new-item-form">
      <h3 className="fw4 mt5">Add Claim Items</h3>
      <div className="form-inner">
        <div className="form-item">
          <p className="label">Date</p>
          <DayPickerInput
            onDayChange={(d) => setDate(format(d, "dd-MM-yyyy"))}
            format="dd-MM-yyyy"
            formatDate={format}
            placeholder="DD-MM-YYYY"
          />
        </div>
        <div className="form-item">
          <p className="label">Item Name</p>
          <input
            value={itemName}
            onChange={(e) => setName(e.target.value)}
            placeholder="(i.e. Dinner after 630pm)"
          />
        </div>
        <div className="form-item">
          <p className="label">Select Category</p>
          <select
            value={itemCategory}
            onChange={(e) => setCategory(e.target.value)}
            name="itemCategory"
          >
            {props.categories.map((x, i) => (
              <option value={x.categoryName} key={x.categoryId}>
                {x.categoryName}
              </option>
            ))}
          </select>
        </div>
        <div className="form-item">
          <p className="label">Amount</p>
          <input
            value={itemAmount}
            onChange={(e) => {
              if (!isNaN(e.target.value)) {
                setAmount(e.target.value);
              }
            }}
          />
        </div>
        <div className="form-item">
          <p className="label">Select Client</p>
          <select
            value={client}
            onChange={(e) => setClient(e.target.value)}
            name="client"
          >
            {props.clients.map((x, i) => (
              <option value={x.clientId} key={x.clientId}>
                {x.clientName}
              </option>
            ))}
          </select>
        </div>
        <div className="form-item">
          <p className="label">Job Number</p>
          <input
            type="text"
            value={jobDescription}
            onChange={(e) => setJob(e.target.value)}
            placeholder="(optional)"
          />
        </div>
        <div className="form-item receipt">
          <p className="label">Receipt</p>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            {file == undefined ? (
              <p>Drop receipt here or click to select from device</p>
            ) : (
              <p>{file.path}</p>
            )}
          </div>
        </div>
        <button onClick={submitForm}>Add Item</button>
      </div>
    </div>
  );
};

export default NewClaim;
