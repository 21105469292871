import React, { useState } from "react";
import Header from "./header";

export const UserContext = React.createContext();

const Wrapper = props => {
  let [userProp, updateProp] = useState(props.checkLogin());

  return (
    <UserContext.Provider value={{ userProp, updateProp }}>
      <div className="gray sans-serif">
        <Header user={userProp} />
        {props.children}
        {userProp != false && (
          <small className="info">Logged in as {userProp.name}</small>
        )}
      </div>
    </UserContext.Provider>
  );
};
export default Wrapper;
