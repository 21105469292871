import React, { useState, useEffect } from "react";
import { fromUnixTime, format } from "date-fns";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image
} from "@react-pdf/renderer";
import { getClientName } from "../helpers";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 20
  },
  fonts: {
    f1: {
      fontSize: 21,
      fontWeight: 700
    },
    f2: {
      fontSize: 15,
      marginTop: 10,
      marginBottom: 10
    },
    tableHeader: {
      fontSize: 18,
      fontWeight: "bold",
      textDecoration: "underline"
    }
  },
  topSection: {
    margin: 10,
    padding: 10
  },
  item: {
    flexDirection: "row",
    marginBottom: 20,
    marginTop: 40
  },
  itemImage: {
    width: 180
  },
  itemInner: {
    marginLeft: 10,
    fontSize: 13
  },
  total: {
    padding: 4,
    backgroundColor: "skyblue",
    color: "#FFF"
  }
});

const ClaimReport = props => {
  const [data, setData] = useState();
  const [items, setItems] = useState();
  const [clients, setClients] = useState();
  const [total, setTotal] = useState();

  useEffect(() => {
    let [data, items, clients, total] = props.location.state;
    setData(data);
    setItems(items);
    setClients(clients);
    setTotal(total);
    console.log({ data, items, clients, total });
  });

  if (data == undefined) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="pdf-viewer">
        <PDFViewer>
          <MyDoc data={data} items={items} clients={clients} total={total} />
        </PDFViewer>
      </div>
    );
  }
};

const MyDoc = ({ data, items, clients, total }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.topSection}>
        <Text style={styles.fonts.f1}>
          Claim Report - {format(Date.parse(data.claimMonth), "MMMM yyyy")}
        </Text>
        <Text style={styles.fonts.f2}>Submitted By: {data.claimeeName}</Text>
        <Text style={styles.fonts.f2}>
          Last modified on{" "}
          {format(fromUnixTime(data.claimLastModifiedDate), "dd/MM/yy")} by{" "}
          {data.claimLastModifiedBy}. Amounts shown are in {data.claimCurrency}
        </Text>
      </View>
      <View style={styles.topSection}>
        <Text style={styles.fonts.tableHeader}>Items</Text>
        {items.map((x, i) => (
          <View style={styles.item} key={i}>
            <Image src={x.itemReceipt} style={styles.itemImage} />
            <View style={styles.itemInner}>
              <Text>{x.itemName}</Text>
              <Text>Category: {x.itemCategory}</Text>
              <Text>Client: {getClientName(x.clientId, clients)}</Text>
              <Text>Amount: {x.itemAmount}</Text>
            </View>
          </View>
        ))}
        <Text style={styles.total}>Total: {total}</Text>
      </View>
    </Page>
  </Document>
);

export default ClaimReport;
