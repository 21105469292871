import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BankDetailsSection } from "./adminViewUser";
const baseUrl = process.env.BASEURL;

class Profile extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    axios
      .post(`${baseUrl}/user/profile`)
      .then((res) => {
        this.setState({
          userData: res.data,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  render() {
    if (this.state.loading) {
      return <p>Loading..</p>;
    } else {
      let d = this.state.userData;
      return (
        <div className="user-profile">
          <div className="profile-top">
            <div className="image-container">
              <img src={d.userImageURL} alt={d.userName} />
              <button className="alt">Upload New Profile Image 🤳</button>
            </div>
            <div className="info-container">
              <h1>{d.userName}</h1>
              <h2>📧 {d.userEmail}</h2>
              <h2>📱 {d.userPhoneNumber}</h2>
              <h3>👤 {d.userRole}</h3>
              <h3>📆 You joined the company on {d.userJoinDate}</h3>
            </div>
          </div>
          {d.hasBankDetails == true ? (
            <BankDetailsSection data={d} />
          ) : (
            <MissingBankDetails />
          )}
        </div>
      );
    }
  }
}

const MissingBankDetails = (props) => (
  <div className="missing-details">
    <p>
      You have yet to add in your Bank Details to the system. Please go ahead
      and add them in <Link to="/user/updateBankDetails">here</Link>.
    </p>
  </div>
);

export default Profile;
