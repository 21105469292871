import React, { Component } from "react";
import store from "store";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import { fromUnixTime, format, getMonth, formatDistance } from "date-fns";
const baseUrl = process.env.BASEURL;

class Home extends Component {
  state = { loading: true };

  componentWillMount() {
    let user = store.get("user");
    try {
      let res;
    } catch (e) {
      console.log(e);
    }
    this.setState({ username: user.name, role: user.role });
  }

  componentDidMount() {
    axios
      .post(`${baseUrl}/user/viewClaims`)
      .then((res) => {
        if (res.data.auth == false) {
          if (res.data.msg == "TOKEN_INVALID") {
            let x = alert("Your session has timed out, please log in again");
            store.remove("user");
            window.location.reload();
            this.setState({
              session_timeout: true,
            });
          }
        }
        let claims = res.data.reverse();
        let archivedClaims = claims.filter((x) => x.claimStatus == "ARCHIVED");
        claims = claims.filter((x) => x.claimStatus != "ARCHIVED");
        this.setState({ claims, archivedClaims, loading: false });
      })
      .catch((e) => console.log("error", e));
  }

  render() {
    if (this.state.session_timeout == true) {
      return <Redirect to="/login" />;
    }

    if (this.state.loading) {
      return <div>Loading..</div>;
    } else {
      return (
        <div className="home">
          <div className="top-btns">
            <Link to="/addNewClaim">
              <button className="btn">Start A New Claim</button>
            </Link>
            {this.state.role == "ADMIN" && (
              <Link to="/admin">
                <button className="ml3 alt">
                  View Claims Submitted to You
                </button>
              </Link>
            )}
            {this.state.role == "FINANCE" && (
              <Link to="/finance">
                <button className="ml3 alt">View All Submitted Claims</button>
              </Link>
            )}
          </div>

          <div className="claims-container-wrapper">
            <h2>Active Claims</h2>
            <div className="claims-container">
              {this.state.claims.length > 0 ? (
                this.state.claims.map((x, i) => (
                  <ClaimItem
                    key={i}
                    dc={fromUnixTime(x.claimDate)}
                    month={x.claimMonth}
                    status={x.claimStatus}
                    approved={x.isApproved}
                    lastModifiedUser={x.claimLastModifiedBy}
                    lastModifiedDate={fromUnixTime(x.claimLastModifiedDate)}
                    id={x.claimId}
                  />
                ))
              ) : (
                <p>You don't have any at the moment.</p>
              )}
            </div>
          </div>
          <div className="claims-container-wrapper">
            <h2>Archived Claims</h2>
            <div className="claims-container">
              {this.state.archivedClaims.length > 0 ? (
                this.state.archivedClaims.map((x, i) => (
                  <ClaimItem
                    key={i}
                    dc={fromUnixTime(x.claimDate)}
                    month={x.claimMonth}
                    status={x.claimStatus}
                    approved={x.isApproved}
                    lastModifiedUser={x.claimLastModifiedBy}
                    lastModifiedDate={fromUnixTime(x.claimLastModifiedDate)}
                    id={x.claimId}
                  />
                ))
              ) : (
                <p>You don't have any at the moment</p>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

const ClaimItem = (props) => {
  return (
    <div className="item">
      <h1>{format(Date.parse(props.month), "MMMM yyyy")}</h1>
      <h3 className="mb1">Created on {format(props.dc, "dd/MM/yyyy")}</h3>
      <h4 className={props.status}>{props.status}</h4>
      <p>
        Last modified by {props.lastModifiedUser}{" "}
        <span className="bold">
          {formatDistance(props.lastModifiedDate, new Date())}{" "}
        </span>
        ago
      </p>
      <Link to={`/viewClaim/${props.id}`}>
        <button className="blk">View Claim</button>
      </Link>
    </div>
  );
};
export default Home;
