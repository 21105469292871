import React, { Component } from "react";
import { Danger } from "../components/alert";
import { UserContext } from "../components/wrapper";
import axios from "axios";
import store from "store";
import { Redirect, Link } from "react-router-dom";
const baseUrl = process.env.BASEURL;

class Login extends Component {
  state = {
    username: "",
    password: "",
    triedToAccessAdmin: false,
    redirectToReset: false,
  };

  componentWillMount() {
    let user = store.get("user");
    let isLoggedIn = false;
    if (user != undefined) {
      isLoggedIn = true;
    }
    if (this.props.location.state && this.props.location.state.error) {
      let errorMsg = "";
      let triedToAccessAdmin = false;
      switch (this.props.location.state.error) {
        case "NOT_LOGGED_IN":
          errorMsg = "You have to login before you can view this page.";
          break;
        case "NOT_ADMIN":
          errorMsg = "Sorry, you need to be an admin to view this page.";
          triedToAccessAdmin = true;
          break;
        case "SESSION_EXPIRED":
          errorMsg = "Your login session has expired, please login again.";
          break;
      }
      this.setState({ errorMsg, triedToAccessAdmin, isLoggedIn });
    }
  }

  componentDidMount() {}

  updateUsername = (e) => {
    this.setState({ username: e.target.value });
  };

  updatePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  handleSubmit = (ctx, event) => {
    event.preventDefault();
    axios
      .post(`${baseUrl}/login`, {
        email: this.state.username,
        password: this.state.password,
      })
      .then((res) => {
        if (res.data.auth === false) {
          let errorMsg = "";
          switch (res.data.msg) {
            case "USER_NOT_EXIST":
              errorMsg =
                "Sorry, this user doesn't seem to exist in our database.";
              break;
            case "PW_INCORRECT":
              errorMsg =
                "Looks like you've got the wrong password. Try again maybe?";
              break;
          }
          this.setState({ errorMsg });
        } else {
          store.set("user", res.data);
          axios.defaults.headers["x-access-token"] = res.data.token;
          ctx.updateProp(res.data);
          if (res.data.hasLoggedIn == 0) {
            this.setState({ redirectToReset: true, triedToAccessAdmin: false });
          } else {
            this.setState({ isLoggedIn: true });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    if (this.state.redirectToReset == true) {
      return <Redirect to="/first_login" />;
    }
    if (
      this.state.isLoggedIn === true &&
      this.state.triedToAccessAdmin == false
    ) {
      return <Redirect to="/" />;
    }

    if (
      this.state.triedToAccessAdmin == true &&
      this.state.isLoggedIn === true
    ) {
      return (
        <div className="tried-admin">
          <Danger message={this.state.errorMsg} />
          <Link to="/" className="btn dib mb3">
            Go Home
          </Link>
          <button className="alt dib" onClick={() => store.remove("user")}>
            Log Out
          </button>
        </div>
      );
    }

    return (
      <UserContext.Consumer>
        {(context) => (
          <div className="login">
            {this.state.errorMsg && <Danger message={this.state.errorMsg} />}
            <div className="form-wrapper">
              <form onSubmit={(event) => this.handleSubmit(context, event)}>
                <p className="label">Email</p>
                <input type="email" onChange={this.updateUsername} />
                <p className="label">Password</p>
                <input
                  type="password"
                  onChange={this.updatePassword}
                  type="password"
                />
                <button type="submit">Log In</button>
              </form>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default Login;
