import React, { Component } from "react";
import axios from "axios";
import { fromUnixTime, format } from "date-fns";
import { Link, Redirect } from "react-router-dom";
import store from "store";
import Toggle from "react-toggle";
const baseUrl = process.env.BASEURL;

class AdminHome extends Component {
  state = {
    loading: true,
    showArchivedClaims: false,
  };

  componentDidMount() {
    let user = store.get("user");
    axios
      .post(`${baseUrl}/admin/viewClaims`)
      .then((res) => {
        if (res.data.auth != true) {
          if (res.data.msg == "TOKEN_INVALID") {
            confirm("Your session has expired, please log in again");
            this.setState({
              session_expired: true,
            });
          }
        }
        let [archivedClaims, submittedClaims] = res.data;
        this.setState({
          items: submittedClaims,
          archivedClaims: archivedClaims,
          loading: false,
          session_expired: false,
          user,
        });
      })
      .catch((e) => console.log(e));
  }

  toggleArchive = () => {
    this.setState({
      showArchivedClaims: !this.state.showArchivedClaims,
    });
  };

  render() {
    if (this.state.loading === true) {
      return <p>Loading...</p>;
    }

    if (this.state.session_expired == true) {
      return (
        <Redirect
          to={{ pathname: "/login", state: { error: "SESSION_EXPIRED" } }}
        />
      );
    }

    return (
      <div className="fl w-100 admin-claims">
        <h1>Claims Submitted to You</h1>
        <div className="claims-container">
          {this.state.items.length > 0 &&
            this.state.items.map((x, i) => (
              <div key={i} className="item">
                <h2 className="fw3">
                  {x.claimeeName}'s claim for <br />
                  <span className="fw5">
                    {format(Date.parse(x.claimMonth), "MMMM yyyy")}
                  </span>
                </h2>
                <p>
                  Last modified by <em>{x.claimLastModifiedBy}</em> on{" "}
                  {format(fromUnixTime(x.claimLastModifiedDate), "dd/MM/yyyy")}
                </p>
                <Link
                  to={{ pathname: `/admin/viewClaim/${x.claimId}`, state: x }}
                >
                  <button>View Claim</button>
                </Link>
              </div>
            ))}
          {this.state.items.length < 1 && (
            <p>There haven't been any submitted claims yet</p>
          )}
        </div>
        <label className="toggle">
          <Toggle
            defaultChecked={this.state.showArchivedClaims}
            onChange={this.toggleArchive}
          />
          <span>Show Archived Claims</span>
        </label>
        <div className="claims-container">
          {this.state.showArchivedClaims &&
            this.state.archivedClaims.map((x, i) => (
              <div key={i} className="item">
                <h2 className="fw3">
                  {x.claimeeName}'s claim for <br />
                  <span className="fw5">
                    {format(Date.parse(x.claimMonth), "MMMM yyyy")}
                  </span>
                </h2>
                <p>
                  Last modified by <em>{x.claimLastModifiedBy}</em> on{" "}
                  {format(fromUnixTime(x.claimLastModifiedDate), "dd/MM/yyyy")}
                </p>
                <Link
                  to={{ pathname: `/admin/viewClaim/${x.claimId}`, state: x }}
                >
                  <button>View Claim</button>
                </Link>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default AdminHome;
