import React, { Component } from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
const baseUrl = process.env.BASEURL;

class ViewCategories extends Component {
  state = {
    loading: true,
    categoryName: "",
  };

  componentDidMount() {
    axios
      .post(`${baseUrl}/getCategories`)
      .then((res) => {
        if (res.data.auth != true) {
          if (res.data.msg == "TOKEN_INVALID") {
            confirm("Your session has expired, please log in again");
            this.setState({
              session_expired: true,
            });
          }
        }
        this.setState({
          items: res.data,
          loading: false,
          session_expired: false,
        });
      })
      .catch((e) => console.log(e));
  }

  handleNewCategory = (e) => {
    this.setState({
      categoryName: e.target.value,
    });
  };

  addNewCategory = () => {
    let x = window.confirm(
      `Are you sure you want to add ${this.state.categoryName} to the database. This action cannot be undone.`
    );
    if (x == true) {
      axios
        .post(`${baseUrl}/admin/newCategory`, {
          categoryName: this.state.categoryName,
        })
        .then((res) => {
          if (res.status == 200) {
            let items = this.state.items;
            items.push({
              categoryId: res.data.lastInsertRowid,
              categoryName: this.state.categoryName,
            });
            this.setState({
              items,
              categoryName: "",
            });
          }
        });
    }
  };

  render() {
    if (this.state.loading == true) {
      return <div>loading...</div>;
    }
    if (this.state.session_expired == true) {
      return (
        <Redirect
          to={{ pathname: "/login", state: { error: "SESSION_EXPIRED" } }}
        />
      );
    }
    return (
      <div className="categories">
        <h2>Current Categories</h2>
        <ul className="pl0 list">
          {this.state.items.map((x, i) => (
            <li key={x.categoryId}>{x.categoryName}</li>
          ))}
        </ul>
        <div className="input-wrapper">
          <input
            type="text"
            value={this.state.categoryName}
            onChange={this.handleNewCategory}
          />
          <button onClick={this.addNewCategory}>Add Category</button>
        </div>
      </div>
    );
  }
}

export default ViewCategories;
