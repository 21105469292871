import React, { Component, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import store from "store";

import Home from "./home";
import Login from "./login";
import Wrapper from "../components/wrapper";
import NewClaim from "./newClaim";
import Claim from "./viewClaim";
import UpdateClaim from "./updateClaim";
import AdminHome from "./adminHome";
import FinanceHome from "./financeHome";
import AdminViewClaim from "./adminViewClaim";
import FinanceViewClaim from "./financeViewClaim";
import ViewClients from "./clients";
import ViewCategories from "./categories";
import NewEmployee from "./newEmployee";
import FirstLogin from "./firstLogin";
import BulkImport from "./bulkImportUsers";
import BankDetailsForm from "./updateBankDetails";
import ViewUsers from "./viewUsers";
import AdminViewUser from "./adminViewUser";
import Profile from "./profile";
import ClaimReport from "./claimAsPdf.js";

const checkLogin = () => {
  if (store.get("user") == undefined) {
    return false;
  } else {
    let user = store.get("user");
    return user;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  let user = checkLogin();
  return (
    <Route
      {...rest}
      render={(props) =>
        user != false ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { error: "NOT_LOGGED_IN" },
            }}
          />
        )
      }
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  let user = checkLogin();
  return (
    <Route
      {...rest}
      render={(props) =>
        user.role === "ADMIN" || user.role === "FINANCE" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { error: "NOT_ADMIN" } }}
          />
        )
      }
    />
  );
};

const MyRouter = (props) => {
  //const [userProp, setUserProp] = useState();

  // const updateProps = () => {
  //   let user = store.get("user");
  //   console.log(user);
  // };

  return (
    <Router>
      <Wrapper checkLogin={() => checkLogin()}>
        <Switch>
          <PrivateRoute path="/" exact component={Home} />
          <Route path="/login" render={(props) => <Login {...props} />} />
          <PrivateRoute path="/addNewClaim" component={NewClaim} />
          <AdminRoute path="/clients" component={ViewClients} />
          <AdminRoute path="/categories" component={ViewCategories} />
          <PrivateRoute path="/viewClaim/:id" component={Claim} />
          <PrivateRoute path="/first_login" component={FirstLogin} />
          <PrivateRoute path="/updateClaim" component={UpdateClaim} />
          <AdminRoute path="/admin" component={AdminHome} exact />
          <AdminRoute path="/finance" component={FinanceHome} exact />
          <AdminRoute path="/newEmployee" component={NewEmployee} />
          <AdminRoute path="/admin/viewClaim/:id" component={AdminViewClaim} />
          <AdminRoute
            path="/finance/viewClaim/:id"
            component={FinanceViewClaim}
          />
          <AdminRoute path="/bulkImport" component={BulkImport} />
          <PrivateRoute
            path="/user/updateBankDetails"
            component={BankDetailsForm}
          />
          <AdminRoute path="/viewUsers" component={ViewUsers} />
          <AdminRoute path="/claimreport" component={ClaimReport} />
          <AdminRoute path="/viewUser/:id" component={AdminViewUser} />
          <PrivateRoute path="/profile" component={Profile} />
        </Switch>
      </Wrapper>
    </Router>
  );
};

export default MyRouter;
