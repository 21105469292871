import React, { Component } from "react";
import Autosuggest from "react-autosuggest";

const getSuggestionValue = suggestion => {
  return suggestion.userEmail;
};

class AdminInputSuggest extends Component {
  state = {
    value: "",
    suggestions: []
  };

  onChange = (event, { newValue }) => {
    this.props.oc(newValue);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const adminList = this.props.admins;

    return inputLength === 0
      ? []
      : adminList.filter(
          admin =>
            admin.userEmail.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  renderSuggestion = suggestion => {
    return <div className="suggestions-holder">{suggestion.userEmail}</div>;
  };
  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      value: this.props.value,
      onChange: this.onChange
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

export default AdminInputSuggest;
