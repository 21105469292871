import React from "react";
import S3 from "aws-s3";
import imageCompression from "browser-image-compression";

const compressOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 800
};

const config = {
  bucketName: "stacke-images",
  dirName: "receipts" /* optional */,
  region: "ap-southeast-1",
  accessKeyId: process.env.AWS_AK_ID,
  secretAccessKey: process.env.AWS_SECRET
};

const S3Client = new S3(config);

export const getImageUrl = async img => {
  const compressedFile = await imageCompression(img, compressOptions);
  let data = await S3Client.uploadFile(compressedFile);
  return data.location;
};

export const getClientName = (id, array) => {
  let name = "";
  for (let x in array) {
    if (array[x].clientId == id) {
      name = array[x].clientName;
    }
  }
  return name;
};

export const checkDateEnding = date => {
  let array = date.split("/");
  if (array[array.length - 1].length == 2) {
    array[array.length - 1] = "20" + array[array.length - 1];
    let newDate = array.join("/");
    return newDate;
  } else {
    return date;
  }
};
