import React, { Component } from "react";
import axios from "axios";
import { fromUnixTime, format, getMonth, formatDistance } from "date-fns";
import { Link, Redirect } from "react-router-dom";
import { getClientName } from "../helpers";
const baseUrl = process.env.BASEURL;

class Claim extends Component {
  state = { loading: true };

  componentDidMount() {
    axios
      .post(`${baseUrl}/user/viewClaim`, {
        claimId: this.props.match.params.id,
      })
      .then((res) => {
        let { claimData, claimItemArray } = res.data;
        this.setState({
          data: claimData,
          items: claimItemArray,
        });
      })
      .then(() => {
        this.calcTotal();
      })
      .then(() => {
        axios.post(`${baseUrl}/viewClients`).then((res) => {
          this.setState({
            clients: res.data,
            loading: false,
          });
        });
      })
      .catch((e) => console.log(e));
  }

  toFullDate = (d) => {
    return format(fromUnixTime(d), "dd/MM/yyyy");
  };
  toMonthAndYear = (d) => {
    return format(fromUnixTime(d), "MMMM yyyy");
  };

  calcTotal = () => {
    let array = this.state.items;
    let x = array.reduce((a, b) => ({
      itemAmount: a.itemAmount + b.itemAmount,
    }));
    this.setState({ total: x.itemAmount });
  };

  archiveClaim = () => {
    axios
      .post(`${baseUrl}/user/archiveClaim`, {
        claimId: this.state.data.claimId,
      })
      .then((res) => {
        if (res.data.changes > 0) {
          this.setState({ hasBeenArchived: true });
        }
      })
      .catch((e) => console.log(e));
  };

  submitClaim = () => {
    axios
      .post(`${baseUrl}/user/submitClaim`, {
        claimId: this.state.data.claimId,
      })
      .then((res) => {
        if (res.data.changes > 0) {
          this.setState({ hasBeenArchived: true });
        }
      })
      .catch((e) => console.log(e));
  };

  render() {
    let { data, items, clients } = this.state;
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    if (this.state.hasBeenArchived) {
      return <Redirect to="/" />;
    }

    return (
      <div className="view-claim">
        <Link to="/">
          <p className="mb5 link dim">🔙 Back to Dashboard</p>
        </Link>
        <div className="claim-top">
          <h2 className="f2 mt3">
            {data.claimeeName}'s Claim Form for{" "}
            {format(Date.parse(data.claimMonth), "MMMM yyyy")}
          </h2>
          <h3 className="f4">This claim is {data.claimStatus}</h3>
          <p className="fw3">
            Claim created on {this.toFullDate(data.claimDate)}, and last
            modified on {this.toFullDate(data.claimLastModifiedDate)} by{" "}
            {data.claimLastModifiedBy}
          </p>
          <p>Currency: {data.claimCurrency}</p>
        </div>
        <ClaimTable items={items} clients={clients} />
        <div className="claim-details">
          <p className="total">
            Total: {data.claimCurrency} {this.state.total.toLocaleString()}
          </p>
          <h4 className="mt2 lh-copy">Additional Notes</h4>
          <p className="mt2 lh-copy mb5">
            {data.NOTES
              ? data.NOTES
              : "No notes have been added to this claim."}
          </p>
        </div>
        <div className="flex mt3 btns">
          {data.claimStatus != "ARCHIVED" && (
            <button className="alt" onClick={this.archiveClaim}>
              Archive Claim 🗄️
            </button>
          )}
          {data.claimStatus == "OPEN" && (
            <>
              <Link to={{ pathname: "/updateClaim", state: this.state }}>
                <button className="alt">Update Claim ✎</button>
              </Link>
              <button onClick={this.submitClaim} className="big-btn">
                Lock &amp; Submit Claim
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
}

const ClaimTable = ({ items, clients }) => (
  <table className="collapse mv4 ba br2 b--black-10 pv2 ph3">
    <thead>
      <tr className="striped--near-white">
        <th className="pv2 ph3 tl f6 fw6 ttu">Date</th>
        <th className="pv2 ph3 tl f6 fw6 ttu">Name</th>
        <th className="pv2 ph3 tl f6 fw6 ttu">Category</th>
        <th className="pv2 ph3 tl f6 fw6 ttu">Receipt</th>
        <th className="pv2 ph3 tl f6 fw6 ttu">Hard Copy Submitted?</th>
        <th className="pv2 ph3 tl f6 fw6 ttu">Client</th>
        <th className="pv2 ph3 tl f6 fw6 ttu">Job Description</th>
        <th className="pv2 ph3 tl f6 fw6 ttu">Amount</th>
        <th className="pv2 ph3 tl f6 fw6 ttu">Is Approved?</th>
      </tr>
    </thead>
    <tbody>
      {items.map((x, i) => (
        <tr className="striped--near-white" key={i}>
          <td className="pv2 ph3 tl fw3">{x.date}</td>
          <td className="pv2 ph3 tl fw3">{x.itemName}</td>
          <td className="pv2 ph3 tl fw3">{x.itemCategory}</td>
          <td className="pv2 ph3 tl fw3">
            <a href={x.itemReceipt} target="_blank">
              Link
            </a>
          </td>
          <td className="pv2 ph3 tl fw3">
            {x.hardCopySubmitted == 0 ? "✖" : "✔"}
          </td>
          <td className="pv2 ph3 tl fw3">
            {x.clientId ? getClientName(x.clientId, clients) : "TKTK"}
          </td>
          <td className="pv2 ph3 tl fw3">
            {x.jobDescription == null ? "N/A" : x.jobDescription}
          </td>
          <td className="pv2 ph3 tl fw3">{x.itemAmount.toLocaleString()}</td>
          <td className="pv2 ph3 tl fw3">{x.isApproved == 0 ? "✖" : "✔"}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default Claim;
