import React, { Component } from "react";
import axios from "axios";
import { fromUnixTime, format } from "date-fns";
import { Link, Redirect } from "react-router-dom";
import store from "store";
const baseUrl = process.env.BASEURL;

class FinanceHome extends Component {
  state = {
    loading: true,
    showArchivedClaims: false,
  };

  componentDidMount() {
    let user = store.get("user");
    axios
      .post(`${baseUrl}/finance/viewClaims`)
      .then((res) => {
        console.log(res.data);
        if (res.data.auth != true) {
          if (res.data.msg == "TOKEN_INVALID") {
            confirm("Your session has expired, please log in again");
            this.setState({
              session_expired: true,
            });
            store.remove("user");
            window.location.reload();
          }
        }
        this.setState({
          data: res.data,
          loading: false,
          session_expired: false,
          user,
        });
      })
      .catch((e) => console.log(e));
  }

  render() {
    if (this.state.loading === true) {
      return <p>Loading...</p>;
    }

    if (this.state.session_expired == true) {
      return (
        <Redirect
          to={{ pathname: "/login", state: { error: "SESSION_EXPIRED" } }}
        />
      );
    }

    return (
      <div className="fl w-100 admin-claims">
        <h1>Claims By Users</h1>
        <div className="claim-container">
          <table>
            <thead>
              <tr>
                <td>Date</td>
                <td>Claimee</td>
                <td>Admin</td>
                <td>Status</td>
                <td>Amount</td>
                <td>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              {this.state.data.length > 0 &&
                this.state.data.map((x, i) => (
                  <tr key={i}>
                    <td>{format(Date.parse(x.claimMonth), "MMMM yyyy")}</td>
                    <td>{x.userEmail}</td>
                    <td>{x.adminEmail}</td>
                    <td>{x.claimStatus}</td>
                    <td>
                      {x.claimCurrency} {x.claimAmount.toLocaleString()}
                    </td>
                    <td>
                      <Link
                        to={{
                          pathname: `/finance/viewClaim/${x.claimId}`,
                          state: x,
                        }}
                      >
                        <button>View Claim</button>
                      </Link>
                    </td>
                  </tr>
                ))}
              {this.state.data.length < 1 && (
                <tr>
                  <td colspan="6">
                    There haven't been any submitted claims yet
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default FinanceHome;
