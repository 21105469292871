import React, { useEffect, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import store from "store";
import { UserContext } from "./wrapper";

const Header = ({ user }) => {
  let { userProp } = useContext(UserContext);
  return (
    <div className="header">
      <div className="left">
        <h1>Stacke.</h1>
      </div>
      <div className="right">
        {userProp != false && userProp.role == "ADMIN"
          ? adminMenuItems
          : userMenuItems}
        {userProp == false ? loggedOutState : loggedInState}
      </div>
    </div>
  );
};

export default Header;

const logUserOut = () => {
  store.remove("user");
  document.location.reload();
};

const loggedInState = (
  <>
    <Link to="/profile">Profile</Link>
    <a href="#" onClick={() => logUserOut()}>
      Log Out
    </a>
  </>
);

const loggedOutState = (
  <Link to="/login" className="mr2">
    Login
  </Link>
);

const adminMenuItems = (
  <>
    <Link to="/">Home</Link>
    <Link to="/clients">Clients</Link>
    <Link to="/categories">Categories</Link>
    <Link to="/viewUsers">Users</Link>
  </>
);

const userMenuItems = (
  <>
    <Link to="/">Home</Link>
  </>
);
