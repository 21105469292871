import React, { useState, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
const baseUrl = process.env.BASEURL;

const NewEmployee = (props) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [name, setName] = useState();
  const [role, setRole] = useState("ADMIN");
  const [isSubAdmin, toggleSubAdmin] = useState(false);
  const [phoneNumber, updatePhone] = useState();
  const [joinDate, setJoinDate] = useState();
  const [subAdminTo, setSubAdmin] = useState();
  //const imageElement = useRef(null);

  const submitForm = () => {
    //let image = await getImageUrl(fileEl.current.files[0])
    console.log(role);
    let image =
      "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png";
    let userObj = {
      userDetails: {
        email,
        password,
        name,
        role,
        isSubAdmin,
        phoneNumber,
        joinDate,
        subAdminTo,
        image,
      },
    };

    if (email == null || password == null || name == null || joinDate == null) {
      window.alert("Please fill in all the details before continuing.");
    } else {
      axios
        .post(`${baseUrl}/register`, userObj)
        .then((res) => {
          if (res.data == "USER_EXISTS") {
            window.alert(
              "Seems like this user already exists in our database."
            );
          }
          if (res.data.auth == true) {
            window.alert(
              "This user has been added! You can now add more users or navigate away from this page."
            );
            setEmail("");
            setName("");
            setRole("USER");
            setJoinDate("");
            setSubAdmin("");
            updatePhone("");
          }
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <div className="new-user">
      <Link to="/viewUsers">
        <button className="alt">🔙 All Users</button>
      </Link>
      <h1>Register a new user</h1>
      <h4>
        Or, <Link to="/bulkImport">import users from a CSV file.</Link>
      </h4>
      <div className="form">
        <div className="form-item">
          <p className="label">Name</p>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-item">
          <p className="label">Email</p>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-item">
          <p className="label">Password</p>
          <small>
            Make sure to give the user this password, and we'll prompt them to
            reset it on their first login.
          </small>
          <input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-item">
          <p className="label">User Role</p>
          <select value={role} onChange={(e) => setRole(e.target.value)}>
            <option value="ADMIN">Admin</option>
            <option value="USER">User</option>
            <option value="FINANCE">Finance</option>
          </select>
        </div>
        {role == "ADMIN" && (
          <div className="form-item">
            <p className="label">Is this user a sub-admin?</p>
            <input
              type="checkbox"
              checked={isSubAdmin}
              onChange={(e) => toggleSubAdmin(e.target.checked)}
            />
          </div>
        )}
        {isSubAdmin == true && (
          <div className="form-item">
            <p className="label">Email address of main admin</p>
            <input
              type="email"
              value={subAdminTo}
              onChange={(e) => setSubAdmin(e.target.value)}
            />
          </div>
        )}
        {/* this is for te image later  <div className="form-item">
                          <p className="label">_++_</p>
                          <input type="">
                          </div>
                          */}
        <div className="form-item">
          <p className="label">Phone Number</p>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => updatePhone(e.target.value)}
          />
        </div>
        <div className="form-item">
          <p className="label">User Join Date</p>
          <small>please follow the format of DD/MM/YYYY</small>
          <input
            type="text"
            value={joinDate}
            onChange={(e) => setJoinDate(e.target.value)}
          />
        </div>
        <button onClick={submitForm}>Submit</button>
      </div>
    </div>
  );
};
export default NewEmployee;
